// App.js
import React, { useEffect, lazy, Suspense } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import "./assets/css/theme.css";

import "./App.css";
import { AuthProvider } from "./AuthContext"; // Adjust the import path as necessary
import * as amplitude from "@amplitude/analytics-browser";
import keepme from "./assets/buttons/svgKeepMe/KeepMe_logo_fullcolor_rgb.svg";

const Homepage = lazy(() => import("./components/Homepage"));
const SignIn = lazy(() => import("./components/WelcomeComp/SignIn"));
const SignUp = lazy(() => import("./components/WelcomeComp/SignUp"));
const ProfilePage = lazy(() => import("./components/ProfilePage"));
const PrivateRoute = lazy(() => import("./components/PrivateRoute"));
const SetPassword = lazy(() => import("./components/SetPassword"));
const RegisterCompany = lazy(
  () => import("./components/WelcomeComp/RegisterCompany")
);
const JoinCompany = lazy(() => import("./components/WelcomeComp/JoinCompany"));
const DisplayTemplate = lazy(
  () => import("./components/ProfilePageComp/Templates/DisplayTemplate")
);
const ProfileContextProvider = lazy(
  () => import("./components/Context/ProfileContext")
);
const NoCompanyReg = lazy(
  () => import("./components/WelcomeComp/NoCompanyReg")
);
const PasswordChange = lazy(
  () => import("./components/WelcomeComp/PasswordChange")
);
const ActivateAccount = lazy(
  () => import("./components/WelcomeComp/ActivationSuccess")
);
const RegistrationSuccess = lazy(
  () => import("./components/WelcomeComp/RegistrationSuccess")
);
const PasswordReset = lazy(
  () => import("./components/WelcomeComp/PasswordReset")
);

function App() {
  const TrackPageView = () => {
    const location = useLocation();
    useEffect(() => {
      amplitude.init("5f02cb38e82cad79e26c15267850ff76", {
        serverZone: "EU",
        autocapture: true,
      });
    }, []);

    useEffect(() => {
      const trackedPaths = "/card/";

      if (location.pathname.includes(trackedPaths)) {
        amplitude.track("Card Viewed", {
          page: location.pathname,
        });
      }
    }, [location]);

    return null;
  };
  return (
    <AuthProvider>
      <Router>
        <Suspense
          fallback={
            <div className="app-suspense">
              <img className="app-suspense-img" src={keepme} alt="Keep Me" />
            </div>
          }
        >
          <ProfileContextProvider>
            <TrackPageView />
            <div className="App">
              <Routes>
                <Route path="/" element={<Homepage keepme={keepme} />} />

                <Route path="/signin" element={<SignIn />} />
                <Route path="/signup" element={<SignUp />} />
                <Route
                  path="/registration-success"
                  element={<RegistrationSuccess />}
                />
                <Route path="/activate/:token" element={<ActivateAccount />} />

                <Route path="/password-reset" element={<PasswordReset />} />
                <Route
                  path="/change-password/:token"
                  element={<PasswordChange />}
                />
                <Route path="/userassign" element={<NoCompanyReg />} />

                <Route
                  path="/register-company"
                  element={
                    <PrivateRoute>
                      <RegisterCompany />
                    </PrivateRoute>
                  }
                />
                <Route
                  path="/join-company"
                  element={
                    <PrivateRoute>
                      <JoinCompany />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/profile"
                  element={
                    <PrivateRoute>
                      <ProfilePage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path="/card/:companyName/:username"
                  element={<DisplayTemplate />}
                />

                <Route path="/set-password/:token" element={<SetPassword />} />
              </Routes>
            </div>
          </ProfileContextProvider>
        </Suspense>
      </Router>
    </AuthProvider>
  );
}

export default App;
